<template>
  <v-app dark>
    <!-- NAVBAR  -->
    <vue-confirm-dialog class="dialogStyle"></vue-confirm-dialog>
    <v-alert
      v-if="users.length <= 1"
      v-model="notificationAlert"
      dismissible
      color="#df6464"
      border="left"
      elevation="2"
      max-width="450"
      colored-border
      icon="mdi-message"
      transition="fade-transition"
      :style="alertNotif"
    >
      <div v-for="(item, index) in users" :key="index">
        <strong>{{ item.name }}</strong
        ><br />
        <span>{{ item.message }}</span>
      </div>
      <div></div>
    </v-alert>
    <v-alert
      dismissible
      v-model="notificationAlert"
      color="#df6464"
      border="left"
      elevation="2"
      max-width="450"
      colored-border
      icon="mdi-message"
      transition="fade-transition"
      :style="alertNotif"
      v-else
    >
      <b>You have {{ message.length }} New Notification.</b>
    </v-alert>
    <v-app-bar color="white" height="72" class="pa-0" app>
      <v-app-bar-nav-icon
        @click="drawer = !drawer"
        class="hidden-md-and-up"
      ></v-app-bar-nav-icon>

      <v-toolbar-title>
        <router-link
          :to="Department ? '/correction' : '/composed'"
          tag="span"
          style="cursor: pointer"
        >
          <img height="75px" width="200px" src="../assets/logo1.png" alt="Default logo" />
        </router-link>
      </v-toolbar-title>
      <!-- VERSION -->
      <!-- <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <span
            @click="Timeline()"
            v-bind="attrs"
            v-on="on"
            class="ml-1 mt-1"
            style="font-weight: bold; font-size: 17px; color: #6d6e71; cursor: pointer"
            >{{ "Ver " + versionName }}<v-icon color="#CF1717">mdi-post-outline</v-icon></span
          >

        </template>
        <span>View Logs</span>
      </v-tooltip> -->
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <span
            @click="Timeline()"
            v-bind="attrs"
            v-on="on"
            class="ml-1 mt-1"
            style="
              font-weight: bold;
              font-size: 17px;
              color: #6d6e71;
              cursor: pointer;
              display: flex;
              align-items: center;
            "
          >
            <span style="margin-right: 5px">{{ "Ver " + versionName }}</span>
            <v-icon color="#CF1717" size="20px">mdi-post-outline</v-icon>
            <!-- <v-icon color="#CF1717" size="20px">mdi-mdi-eye</v-icon> -->
          </span>
        </template>
        <span>View Logs</span>
      </v-tooltip>

      <v-spacer></v-spacer>

      <!-- NOTIFICATION -->
      <!-- <v-btn @click="notificationStatus = !notificationStatus">TEST</v-btn> -->
      <!-- {{ test.length }} Online
      <v-icon class="mr-5" color="success">mdi-account-badge</v-icon> -->
      <v-menu
        v-model="menu"
        transition="slide-x-transition"
        offset-y
        width="500"
        :close-on-content-click="false"
      >
        <template v-slot:activator="{ on: attrs }">
          <v-badge
            bordered
            color="error"
            :content="notifCount.count"
            :value="notifCount.countVal"
            overlap
            style="font-size: 3px; cursor: pointer"
          >
            <v-tooltip left>
              <template v-slot:activator="{ on: tooltip }">
                <v-icon
                  v-bind="attrs"
                  v-on="{ ...tooltip}"
                  @click="reUpdateTime()"
                >
                  mdi-bell
                </v-icon>
              </template>
              <span>{{ $t("tooltip.notification") }}</span>
            </v-tooltip>
          </v-badge>
        </template>
        <v-list-item-title
          style="background-color: white; padding: 15px; font-weight: bold"
          v-if="message.length > 0"
        >
          <v-icon color="blue">mdi-message-processing</v-icon>
          {{ $t("tooltip.newMessage") }}</v-list-item-title
        >
        <v-divider></v-divider>
        <v-list v-scrollbar max-width="370" max-height="300" @click="menu = false" :style="loading == true ? 'height:70px; width:150px; margin-left:10px; margin-right:10px' : '' " >
          <!-- LOADERS -->
            <div v-if="loading == true" class="loading">
              Loading ...
            </div>
          <div
            v-if="message.length > 0"
            :style="message.length > 10 ? 'padding-bottom: 15px;' : ''"
          > <!-- MESSAGE IS NOT EMPTY -->
            <v-list-item
              style="cursor: pointer"
              v-for="(item, index) in message"
              :key="index"
              @click="checkInbox(index, item.uniqueID)"
            >
              <v-list-item-avatar>
                <img :src="avatar(item.userID)" @error="alertUrlError" />
              </v-list-item-avatar>
              <v-list-item-content>
                <span>{{ item.name }} </span>
                <v-list-item-subtitle class="alertMessage">{{
                  item.message
                }}</v-list-item-subtitle>
                <span style="font-size: 11px; color: silver">
                  {{ item.updateTime }}
                </span>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn icon>
                  <v-icon color="grey lighten-2">mdi-message-arrow-right-outline</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </div>
          <div class="text-center ma-7" v-else-if="loading == false && message.length == 0"> <!-- MESSAGE IS EMPTY -->
            <v-img
              src="../assets/bell.png"
              width="50"
              style="margin: 0 auto; margin-top: 25px"
            ></v-img>
            <v-list-item-title style="margin: 15px; font-weight: bold; font-size: 16px">
              {{ $t("tooltip.noNotif") }}
            </v-list-item-title>
          </div>
        </v-list>
        <v-hover>
          <template v-slot:default="{ hover }">
            <v-card
              @click="seeMore()"
              :style="
                message.length >= notifCount.count
                  ? 'display:none;'
                  : 'cursor: pointer; background-color: #F9F9F9; text-align: center'
              "
              :elevation="hover ? 24 : 6"
              class="mx-auto pa-3"
            >
              See More...
            </v-card>
          </template>
        </v-hover>
      </v-menu>

      <v-list class="hidden-sm-and-down">
        <v-list-item>
          <v-avatar class="mr-1" style="border: 0.1px solid silver">
            <v-img :src="picture" contain v-on:error="imageLoadOnError"></v-img>
          </v-avatar>
          <v-list-item-content>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-list-item-title style="color: grey" v-bind="attrs" v-on="on">{{
                  name
                }}</v-list-item-title>
              </template>
              <v-list class="text-center pa-0">
                <v-list-item style="cursor: pointer" to="/feedback">
                  <v-list-item-title color="red" style="font-weight: bold">
                    <v-icon color="red">mdi-forum</v-icon>
                    {{
                      language == "en" ? "Feedback" : "フィードバック"
                    }}</v-list-item-title
                  >
                </v-list-item>
                <v-list-item style="cursor: pointer" @click="ConfirmLogout">
                  <v-list-item-title color="red" style="font-weight: bold">
                    <v-icon color="red">mdi-logout</v-icon>
                    {{ language == "en" ? "Logout" : "ログアウト" }}</v-list-item-title
                  >
                </v-list-item>
              </v-list>
            </v-menu>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <LanguageSwitcher />
    </v-app-bar>

    <!-- CONTENT -->
    <v-main>
      <v-navigation-drawer v-model="drawer" width="314" absolute left temporary floating>
        <v-card class="mx-auto" height="100%" elevation="0">
          <v-list>
            <v-list-item>
              <v-list-item-avatar color="primary">
                <v-img :src="picture" contain @error="imageLoadOnError"></v-img>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title style="font-weight: bold">{{
                  name
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider><br />
            <!-- EMAIL -->

            <v-list-item
              color="#df6464"
              v-for="item in email"
              :key="item.en"
              :to="item.path"
            >
              <v-icon>{{ item.icon }}</v-icon>
              <v-list-item-title>{{
                language == "en" ? item.en : item.ja
              }}</v-list-item-title>
            </v-list-item>
            <!-- <v-list-item @click="directToAnotherPage">
                <v-icon>mdi-alpha-g-circle</v-icon>
                <v-list-item-title>{{
                  language == "en" ? "GC Portal System" : "GCポータルシステム"
                }}</v-list-item-title>
              </v-list-item> -->
            <v-list-group
              prepend-icon="mdi-email"
              color="#df6464"
              :value="true"
              no-action
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>{{
                    language == "en" ? "Inbox" : "受信トレイ"
                  }}</v-list-item-title>
                </v-list-item-content>
              </template>

              <v-list-item
                color="#df6464"
                v-for="item in inbox"
                :key="item.en"
                :to="item.path"
              >
                <v-icon>{{ item.icon }}</v-icon>
                <v-list-item-title>
                  {{ language == "en" ? item.en : item.ja }}
                </v-list-item-title>
              </v-list-item>
            </v-list-group>

            <!-- ACCOUNT -->
            <v-list-group color="#df6464" prepend-icon="mdi-account" no-action>
              <template v-slot:activator>
                <v-list-item-title>{{
                  language == "en" ? "Inquiry From HRD" : "HRDからの問い合わせ"
                }}</v-list-item-title>
              </template>
              <v-list-item color="#df6464" to="/template">
                <v-icon>mdi-book</v-icon>
                <v-list-item-title>{{
                  language == "en" ? "Template settings" : "テンプレート設定"
                }}</v-list-item-title></v-list-item
              >
              <v-list-item
                v-if="EmployeeCode == '00000' || empEmail == 's-fukaya@hrd-s.com'"
                color="#df6464"
                to="/category"
              >
                <v-icon>mdi-account-key</v-icon>
                <v-list-item-title>
                  {{
                    language == "en" ? "Inquiry Master" : "問い合わせ担当者マスター"
                  }}</v-list-item-title
                ></v-list-item
              >
            </v-list-group>
            <v-list-item style="cursor: pointer" @click="ConfirmLogout">
              <v-icon color="red" class="mr-7">mdi-logout</v-icon>
              <v-list-item-title style="color: red">{{
                language == "en" ? "Logout" : "ログアウト"
              }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card>
      </v-navigation-drawer>
      <!-- BODY CONTAINER -->
      <v-container
        fluid
        class="d-none d-md-flex"
        :style="
          routerPath == '/composed' || $route.fullPath == '/composed'
            ? 'max-width: 70rem'
            : ''
        "
      >
        <v-row>
          <v-col cols="1" style="margin-right: -30px">
            <SideDrawer />
          </v-col>
          <v-col cols="11">
            <v-card elevation="3" class="d-none d-md-flex card" :style="forReply">
              <transition name="fade" mode="out-in">
                <router-view></router-view>
              </transition>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
      <div class="d-flex d-md-none mt-3">
        <transition name="fade" mode="out-in">
          <router-view></router-view>
        </transition>
      </div>
    </v-main>
    <!-- 
    <v-footer>
      <v-spacer />
    </v-footer
    > -->
  </v-app>
</template>

<script>
import SideDrawer from "@/components/Others/SideDrawer";
import LanguageSwitcher from "@/components/Others/LanguageSwitcher";

import VersionLogsTable from "@/functions/httpRequest/ExternalConnection/VersionLogsTable";
import store from "@/store";
import moment from "moment";
import md5 from "md5";

export default {
  // sockets: {
  // updateReceived: function (socket) {
  //   console.log(socket, "378");
  //   switch (socket.hrdInquiryStatus) {
  //     case "LOGIN":
  //       this.$socket.emit("newUpdate", {
  //         hrdInquiry: this.id,
  //         hrdInquiryStatus: "ONLINE",
  //       });
  //       this.testArr(socket.hrdInquiry);
  //       break;
  //     case "LOGOUT":
  //       this.LOG_OUT(socket.hrdInquiry);
  //       break;
  //     case "ONLINE":
  //       this.testArr(socket.hrdInquiry);
  //       break;
  //     case "notificationAlert":
  //       if (this.empEmail == socket.email) this.getUnreadNotif();
  //       break;
  //   }
  //   // console.log(this.$socket.on(notificationAlert));
  //   // console.log(this.$socket.emit("newUpdate"));
  //   // if (socket.hrdInquiryStatus == "ONLINE") {
  //   //   this.testArr(socket.hrdInquiry);
  //   // }
  //   // this.LOG_OUT(socket.hrdInquiry);
  //   // if (!socket.hrdInquiry) {
  //   //   // CODE INTERVAL TIME
  //   //   this.LOG_OUT(socket.hrdInquiry);
  //   // }
  // },
  // },
  name: "home",
  data() {
    return {
      bellClicked:false,
      notificationCount: 0,
      interalDuration: null,
      notificationStatus: false,
      intervalValue: null,
      offsetCount: 5,
      menu: false,
      versionName: "",
      socket: "",
      notificationAlert: false,
      name: "",
      EmployeeCode: "",
      test: store.state.test,
      UserType: store.state.userinfo.UserType,
      type: store.state.userinfo.Type,
      id: store.state.userinfo.EmployeeCode,
      empEmail: store.state.userinfo.Email,
      Department: store.state.userinfo.Department,
      loading: false,
      avatarUrl: false,
      alertUrl: false,
      snackbar: false,
      drawer: false,
      logoutDialog: false,
      routerPath: "",
      users: [],
      dataExtraction: [
        {
          en: "Data Extraction",
          ja: "データ抽出",
          path: "/extraction",
          icon: "mdi-folder-key-network-outline",
        },
      ],
      inbox: [
        {
          en: "Outbox (inquiring)",
          ja: "送信トレイ（問い合わせ中）",
          path: "/inbox",
          icon: "mdi-inbox",
        },
        {
          en: "Inbox",
          ja: "受信トレイ",
          path: "/received",
          icon: "mdi-email-receive",
        },
        {
          en: "Inbox (CC)",
          ja: "受信トレイ（CC）",
          path: "/SubHandle",
          icon: "mdi-email-variant",
        },
        {
          en: "Save Draft",
          ja: "下書き保存",
          path: "/Draft",
          icon: "mdi-content-save-edit",
        },
        {
          en: "All Inbox Department",
          ja: "すべての受信トレイ部門",
          path: "/AllInboxDepartment",
          icon: "mdi-folder-home-outline",
        },
      ],
      email: [
        {
          en: "Contact HRD",
          ja: "HRDへの問い合わせ",
          path: "/composed",
          icon: "mdi-clipboard-text",
        },
        {
          en: "Correction Request",
          ja: "訂正依頼",
          path: "/correction",
          icon: "mdi-book-check",
        },
        {
          en: "Admin User",
          ja: "管理者ユーザー",
          path: "/adminUser",
          icon: "mdi-book-check",
        },
      ],
      message: [],
      window: {
        width: 0,
        height: 0,
      },
    };
  },
  components: {
    SideDrawer,
    LanguageSwitcher,
  },

  watch: {
    //*****************************************************//
    //    OPEN CLOSE MENU TO FIX NOTIFICATION DISPLAY FOR SHORT NAMES//
    //*****************************************************//
    loading(){
        this.menu = false
        if(this.bellClicked){
          setTimeout(() => {
            this.menu = true
          }, 5);
        }
    },
    //*****************************//
    //    CHECK FOR ROUTE CHANGES  //
    //*****************************//
    $route(to) {
      if (to.path == "/composed") this.routerPath = "/composed";
      else this.routerPath = to.path;
    },
    notificationStatus(val) {
      clearInterval(this.intervalValue);

      // console.log(val, "extra comment");
      // this.notifCount.count >= 1
      // console.log(val);
      if (val) {
        this.intervalValue = setInterval(() => {
          document.getElementById(
            "notificationTitle"
          ).innerHTML = `${this.notifCount.count} NEW EMAIL RECEIVED`;
          setTimeout(() => {
            document.getElementById("notificationTitle").innerHTML = "HRD Inquiry";
          }, 800);
        }, 1500);
      } else {
        setTimeout(() => {
          document.getElementById("notificationTitle").innerHTML = "HRD Inquiry";
        }, 2500);
      }
    },

    message(newValue){ //!TO AUTO FETCH NEW MESSAGE IN NOTIFICATION
      if(newValue.length == 0 && newValue.length < this.notifCount.count){
        setTimeout(() => {
          this.getUnreadNotif()
        }, 100);
      }
    },
  },

  mounted() {
    this.$axios
      .get(`${this.$BaseUrl}/getConfirmationCount/${this.empEmail}`, this.$BaseApiKey)
      .then((res) => {
        this.notificationCount += res.data[0].Count;
      });
    this.GetVersionRecord();
    //***************************************************//
    //   SET INTERVAL FOR UPDATING TIME IN NOTIFICATION  //
    //***************************************************//
    this.durationGet();
  },
  created() {
    // console.log(this.id, "labas");

    // if (this.id) {
    //   // setInterval(() => {
    //   // this.testArr(this.id);
    //   this.$socket.emit("newUpdate", {
    //     hrdInquiry: this.id,
    //     hrdInquiryStatus: "LOGIN",
    //   });
    //   // }, 3000);
    //   // this.$socket.emit("newUpdate", { hrdInquiry: this.id });
    // }

    // CHECK USER TYPE. EXCLUDE SOME TAB
    if (this.type == "Inquiry") {
      this.email.splice(2, 2);
      this.inbox.splice(4, 1);
    } else if (this.type == "Correction") {
      // this.email = this.email.slice(this.email.length - 1);
      this.email = this.email.slice(this.email.length - 1);
      this.inbox.splice(2, 1);
    }

    // CHECK USER LEVEL
    let userLanguage = "";

    if (store.state.userinfo.EmployeeNumber) {
      userLanguage = "ja";
      this.EmployeeCode = store.state.userinfo.EmployeeNumber;
      this.name =
        store.state.userinfo.FirstNameEng + " " + store.state.userinfo.LastNameEng;
    } else {
      userLanguage = "en";
      this.name = store.state.userinfo.FullName;
      this.EmployeeCode = store.state.userinfo.EmployeeCode;
    }

    // if (this.EmployeeCode != "0417") this.email.splice(1, 1);

    // CALCULATE WIDTH & HEIGHT

    window.addEventListener("resize", this.handleResize);
    this.handleResize();

    // LANGUAGE SWITCHER

    this.$i18n.locale = userLanguage;
    //GET NOTIF BACKEND
    this.getUnreadNotif();

    // SOCKET CONNECTION

    this.socket = this.$io;
    // SOCKET IO

    // this.socket.on("sendMessageToClient", (data) => {
    //   if (data.userID != this.EmployeeCode) {
    //     const record = data.afftectedUser.split(",");
    //     const CheckRecord = record.filter((rec) => {
    //       return rec == this.EmployeeCode;
    //     });
    //     if (CheckRecord.length > 0) {
    //       this.users.push({
    //         name: data.name,
    //         userID: data.userID,
    //         message: data.message,
    //       });

    //       this.notificationAlert = true;
    //       this.message.push(data);
    //       setTimeout(() => {
    //         this.notificationAlert = false;
    //         this.users = [];
    //       }, 5000);
    //     }
    //   }
    // });
  },
  computed: {
    // notificationStatus()
    //***********************************//
    // CHANGE BACKGROUND COLOR IN REPLY  //
    //***********************************//
    forReply() {
      const routerPath = this.routerPath.split("/")[2];

      if (routerPath == "reply") return "background-color: #fbfbfb";
      return "";
    },
    //***********************************//
    //     NOTIFICATION COUNTING        //
    //***********************************//
    notifCount() {
      const count = this.notificationCount;
      let countVal = false;

      if (count > 0) countVal = true;
      return {
        count: count,
        countVal: countVal,
      };
    },
    language() {
      return this.$i18n.locale;
    },
    //***********************************//
    //            HRD PICTURE            //
    //***********************************//
    picture() {
      return this.avatarUrl
        ? require("../assets/no-image.png")
        : `http://hrdapps48:3001/ftp/employee_pictures/0${this.EmployeeCode}.jpg`;
    },
    //***********************************//
    //    NOTIFICATION RESPONSIVENESS    //
    //***********************************//

    alertNotif() {
      if (this.window.width >= "1000") {
        return {
          "z-index": 9999,
          position: "absolute",
          top: "70px",
          left: "38%",
        };
      } else if (this.window.width >= "800") {
        return {
          "z-index": 9999,
          position: "absolute",
          top: "70px",
          left: "30%",
        };
      } else {
        return {
          "z-index": 9999,
          position: "absolute",
          top: "70px",
          left: "25%",
        };
      }
    },
  }, //TESTING GIT
  methods: {
    durationGet() {
      this.interalDuration = setInterval(() => {
        if (this.message.length > 0) {
          for (let x in this.message) {
            this.$set(
              this.message[x],
              "updateTime",
              moment(this.message[x].time).startOf().fromNow()
            );
          }
        }
      }, 10000);
    },
    getUnreadNotif() {
      this.loading = true
      // GET NOTIF FROM BACKEND
      this.$axios(
        `${this.$BaseUrl}/getConfirmationNotif/${this.offsetCount}/${this.empEmail}`,
        this.$BaseApiKey
      ).then((res) => {
        let record = [];
        let getArrNotif = res.data.filter((rec) => {
          return rec;
        });
        record = Object.assign(getArrNotif);
        this.message = [];
        for (let x in record) {
          if (record[x].ReplyPerson) {
            record[x].From = record[x].ReplyPerson;
          }
          this.message.push({
            message: record[x].MessageContent,
            name: record[x].From,
            time: moment(record[x].DateCreated).format("YYYY-MM-DD HH:mm:ss"),
            uniqueID: record[x].ConfirmationID,
            userID: record[x].SenderID,
          });
          // console.log(this.message);
        }
        clearInterval(this.intervalDuration);
        this.durationGet();
        if (record.length > 0) {
          this.notificationStatus = true;
        } else {
          this.notificationStatus = false;
        }
        this.loading = false
      });
    },
    seeMore() {
      this.offsetCount = this.offsetCount + 1;
      this.offsetCount++;
      this.$axios(
        `${this.$BaseUrl}/getConfirmationNotif/${this.offsetCount}/${this.empEmail}`,
        this.$BaseApiKey
      ).then((res) => {
        let record = [];
        let getArrNotif = res.data.filter((rec) => {
          return rec;
        });
        record = Object.assign(getArrNotif);
        this.message = [];
        for (let x in record) {
          if (record[x].ReplyPerson) {
            record[x].From = record[x].ReplyPerson;
          }
          this.message.push({
            message: record[x].MessageContent,
            name: record[x].From,
            time: moment(record[x].DateCreated).format("YYYY-MM-DD HH:mm:ss"),
            uniqueID: record[x].ConfirmationID,
            userID: record[x].SenderID,
          });
          // console.log(this.message);
        }
        clearInterval(this.intervalDuration);
        this.durationGet();
        if (record.length > 0) {
          this.notificationStatus = true;
        } else {
          this.notificationStatus = false;
        }
      });
    },
    GetVersionRecord() {
      VersionLogsTable.Get().then((res) => {
        const data = res.data.filter((rec) => {
          if (rec.versionName == "aws") return rec;
        });
        var lastData = data.length - 1;
        this.versionName = data[lastData].Version;
      });
    },

    Timeline() {
      if (this.$route.path != "/timeline") this.$router.push("/timeline");
    },
    //***********************************//
    //      UPDATE NOTICATION SEEN       //
    //***********************************//

    checkInbox(index, conID) {
      this.menu = false;
      this.$axios
        .get(`${this.$BaseUrl}/GetSpecificConfirmation/${conID}`, this.$BaseApiKey)
        .then(async (res) => {
          let addData = "";

          // CHECK FOR USER SEEN THE MESSAGE
          if (res.data[0].SeenBy) {
            addData = res.data[0].SeenBy + this.EmployeeCode + ",";
          } else addData = this.EmployeeCode + ",";

          const dataToUpdate = {
            ConfirmationID: conID,
            SeenBy: addData,
          };

          // UPDATE SEEN MESSAGE
          await this.$axios.put(
            `${this.$BaseUrl}/UpdateConfirmation`,
            { data: dataToUpdate },
            this.$BaseApiKey
          );
        });

      // GET UNIQUE ID OF CONFIRMATION
      const filterRecord = this.message.filter((rec) => {
        return rec.uniqueID == conID;
      });
      let cnt = 0;

      // CHECK FOR RECORD IN API
      this.$axios
        .get(
          `${this.$BaseUrl}/GetSpecificConfirmation/${filterRecord[0].uniqueID}`,
          this.$BaseApiKey
        )
        .then((res) => {
          const splitRecord = res.data[0].To.split(",");
          // CHECK FOR SAME RECORD
          // this.notificationStatus = splitRecord ? true : false;
          for (let x in splitRecord) if (this.name == splitRecord[x]) cnt++;

          // IF NO SAME RECORD PUSH ROUTES IN REPLY
          if (cnt > 0) this.$router.push(`/received/reply/${filterRecord[0].uniqueID}`);
          else this.$router.push(`/inbox/reply/${filterRecord[0].uniqueID}`);
        });

      this.message.splice(index, 1);
      this.notificationCount = this.notificationCount - 1;

      if (this.message.length > 0) {
        // clearInterval(this.intervalValue);
        this.notificationStatus = true;
      } else {
        clearTimeout(this.intervalValue);
        this.notificationStatus = false;
      }
    },
    //***********************************//
    //        LOGOUT FUNCTION            //
    //***********************************//

    ConfirmLogout() {
      this.$confirm({
        title: this.$t("dialog.title"),
        message: this.$t("dialog.messageLogout"),
        button: {
          no: this.$t("dialog.no"),
          yes: this.$t("dialog.yes"),
        },
        callback: (confirm) => {
          if (confirm) {
            this.logout();
          }
        },
      });
    },
    //**********************************************//
    //   UPDATE TIME AFTER ON CLICK NOTIFICATION    //
    //**********************************************//

    reUpdateTime() {
      this.menu = !this.menu
      this.bellClicked = !this.bellClicked 
      for (let x in this.message)
        this.$set(
          this.message[x],
          "updateTime",
          moment(this.message[x].time).startOf().fromNow()
        );
    },
    //*****************************************************//
    //   CHANGE TIME TO FACEBOOK LIKE TIME NOTIFICATION    //
    //   EX. 1 hour ago / 2 days ago / 1 month ago         //
    //*****************************************************//

    updateTime: (time) => moment(time).startOf().fromNow(),

    //***********************************//
    //       MAIN PICTURE AVATAR         //
    //***********************************//

    avatar: (val) => `http://hrdapps48:3001/ftp/employee_pictures/0${val}.jpg`,

    //****************************************//
    //    HANDLE APPLICATION RESPONSIVENESS   //
    //****************************************//

    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
    //*************************//
    // TRUE FOR ERROR PICTURE  //
    //*************************//

    imageLoadOnError() {
      this.avatarUrl = true;
    },
    //**********************************//
    //  CHANGE PICTURE IF NO AVAILABLE  //
    //*********************************//

    alertUrlError: (event) => (event.target.src = require("../assets/no-image.png")),
    //**********************//
    //   LOGOUT FUNCTION    //
    //**********************//

    logout() {
      // this.$socket.emit("newUpdate", {
      //   hrdInquiry: this.id,
      //   hrdInquiryStatus: "LOGOUT",
      // });
      localStorage.clear();
      process.env.NODE_ENV == "development"
        ? window.open(`http://${window.location.host}/login`, "_self") //FOR DEVELOPMENT MODE
        : window.open("https://inquiry.hrd-s.com/login", "_self") //FOR PRODUCTION MODE
        ? window.open(`http://${window.location.host}/login`, "_self") //FOR DEVELOPMENT MODE
        : window.open(
            `http://inquirytestbucket.hrd-s.com.s3-website-ap-southeast-1.amazonaws.com/login`,
            "_self"
          ); //FOR DEVELOPMENT MODE
    },
    //**********************//
    //    UPDATE DRAWER     //
    //**********************//
    update() {
      this.drawer = false;
      // console.log(e);
    },
    //********************************//
    //    FOR AUTOLOGIN IN GC PORTAL  //
    //********************************//
    directToAnotherPage() {
      const email = store.state.userinfo.Email; //USER EMAIL TO BE PASS IN ROUTES PARAM
      const id = store.state.userinfo.EmployeeCode; //EMPLOYEE ID FOR GC USER

      // DONT FORGET TO ENCRYP EMAIL AND ID. FOR SECURITY
      window.open(
        `https://gcportal.hrd-s.com/ja/SignIn?index=${md5(email)}&id=${md5(id)}`,
        `_blank`
      );
    },
  },
};
</script>

<style lang="scss">
.card {
  max-height: calc(90vh - 55px);
  min-height: calc(90vh - 55px);
  height: 10px;
}

.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.5s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
.alertMessage {
  p {
    color: grey;
    font-size: 13px;
    display: inline-block;
    width: 350px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
  }
}

.dialogStyle {
  button {
    color: #df6464;
  }
}

.loading {
  text-align: center;
  color: #fff;
  z-index: 1;
  background: #df6464;
  padding: 8px 18px;
  border-radius: 5px;
  margin-left: -3%;
  margin-top: 8%;
}
</style>
